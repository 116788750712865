import Consultation from 'src/store/models/Consultation';
import User from 'src/store/models/User';
export function ongoing() {
  return Consultation.query().where(function (consult) {
    return consult.isOngoing();
  }).get();
}
export function finished() {
  return Consultation.query().where(function (consult) {
    return consult.isFinished();
  }).get();
}
export function withExams() {
  return Consultation.query().has('exams').get();
}
export function withoutExam() {
  return Consultation.query().hasNot('exams').get();
}
export function usersHavingConsultations() {
  return User.query().withAll().has('consultations').get();

  // return Consultation.query().with('user.shifts').get()
  //     .map(consult => consult.user)
  //     .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) // Unique
  //     .sort((a, b) => {
  //         return a.shifts.length && b.shifts.length
  //             ? a.shifts[0].type.id - b.shifts[0].type.id
  //             : a.id - b.id
  //     })
}