import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import _toConsumableArray from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.find.js";
export function topQuotes(state) {
  return _toConsumableArray(state.settings.quotes).filter(function (q) {
    return q.modifier !== true;
  }).sort(function (a, b) {
    return b.count - a.count;
  });
}
export function chunkedQuotes(state) {
  var size = 9;
  return _toConsumableArray(state.settings.quotes).filter(function (quote) {
    return quote.value !== null;
  }).reduce(function (arr, item, index) {
    var chunk = Math.floor(index / size);
    if (!arr[chunk]) {
      arr[chunk] = []; // New Chunk
    }

    arr[chunk].push(item);
    return arr;
  }, []);
}
export function topPaymentMethods(state) {
  var stateTmp = _objectSpread({}, state);
  var settingsTmp = _objectSpread({}, stateTmp.settings);
  var pmTmp = _toConsumableArray(settingsTmp.payment_methods);

  // return state.settings.payment_methods
  return pmTmp.sort(function (a, b) {
    return b.count - a.count;
  });
}
export function consultationRooms(state) {
  return state.settings.practice.rooms.filter(function (room) {
    return room.type === 'consultation';
  });
}
export function secondaryRooms(state) {
  return state.settings.practice.rooms.filter(function (room) {
    return room.type === 'care';
  });
}
export function wirePaymentMethods(state) {
  return state.settings.practice.payment_methods.filter(function (method) {
    return method.type === 'wire';
  }).map(function (method) {
    return method.name;
  });
}
export function deskPaymentMethods(state) {
  return (state.settings.practice.payment_methods || []).filter(function (method) {
    return method.type === 'desk';
  }).map(function (method) {
    return method.name;
  });
}
export function ncMethods(state) {
  return (state.settings.practice.payment_methods || []).filter(function (method) {
    return method.type === 'nc';
  }).map(function (method) {
    return method.name;
  });
}
export function tncMethods(state) {
  return (state.settings.practice.payment_methods || []).filter(function (method) {
    return method.type === 't-nc';
  }).map(function (method) {
    return method.name;
  });
}
export function originOptions(state) {
  var stats = (state.settings.practice.statistics || []).find(function (stat) {
    return stat.name === 'origin';
  });
  return stats ? stats.options : [];
}
export function hospOptions(state) {
  return (state.settings.practice.statistics || []).find(function (stat) {
    return stat.name === 'hosp';
  }).options;
}