import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import SinceDuration from 'components/partials/SinceDuration';
import StartStop from 'components/partials/StartStop';
export default {
  name: 'Status',
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      since: '',
      procedure: null,
      component: 'SinceDuration'
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    rooms: function rooms(s) {
      return s.auth.settings.practice.rooms;
    }
  })), mapGetters({
    ongoingConsultation: 'entities/consultations/ongoing',
    ongoingExams: 'entities/exams/ongoing',
    withoutExam: 'entities/consultations/withoutExam',
    returners: 'entities/visits/returners',
    latestExams: 'entities/exams/latestAnticipatedEnd',
    newcomers: 'entities/visits/newcomers',
    upcomingAppointments: 'entities/visits/upcomingAppointments'
  })), {}, {
    status: function status() {
      return this.getStatus();
    }
  }),
  methods: {
    getStatus: function getStatus() {
      var _this = this;
      var comingAppointment = this.upcomingAppointments.find(function (c) {
        return c.id === _this.visit.id;
      });
      if (comingAppointment && comingAppointment.isDistant()) {
        this.component = 'SinceDuration';
        this.since = comingAppointment.started_at;
        return 'RDV de téléconsultation';
      }
      if (comingAppointment) {
        this.component = 'SinceDuration';
        this.since = comingAppointment.started_at;
        return 'RDV à venir';
      }
      var ongoingSmur = this.ongoingExams.find(function (e) {
        return e.visit_id === _this.visit.id && e.type === 'smur';
      });
      if (ongoingSmur) {
        this.component = 'StartStop';
        this.procedure = ongoingSmur;
        return 'En attente de SMUR';
      }
      var ongoingHosp = this.ongoingExams.find(function (e) {
        return e.visit_id === _this.visit.id && e.type === 'hosp';
      });
      if (ongoingHosp) {
        this.component = 'StartStop';
        this.procedure = ongoingHosp;
        return 'En attente d\'hospi.';
      }
      var ongoingSurv = this.ongoingExams.find(function (e) {
        return e.visit_id === _this.visit.id && e.type === 'surv';
      });
      if (ongoingSurv) {
        this.component = 'StartStop';
        this.procedure = ongoingSurv;
        return 'En surveillance';
      }
      var ongoingExam = this.ongoingExams.find(function (e) {
        return e.visit_id === _this.visit.id;
      });
      if (ongoingExam) {
        this.component = 'StartStop';
        this.procedure = ongoingExam;
        return 'En attente de résultat';
      }
      var ongoingConsultation = this.ongoingConsultation.find(function (v) {
        return v.visit_id === _this.visit.id;
      });
      if (ongoingConsultation && this.visit.isDistant()) {
        this.component = 'SinceDuration';
        this.since = ongoingConsultation.started_at;
        return 'Téléconsultation en cours';
      }
      if (ongoingConsultation) {
        this.component = 'SinceDuration';
        this.since = ongoingConsultation.started_at;
        return 'En consultation';
      }
      if (this.withoutExam.some(function (c) {
        return c.visit_id === _this.visit.id;
      })) {
        this.component = false;
        return 'En attente de facturation';
      }
      var rooms = this.$store.state.auth.settings.practice.rooms;
      var room = this.visit.priority ? rooms.find(function (r) {
        return r.name === _this.visit.notes.room;
      }) : false;
      if (this.returners.some(function (v) {
        return v.id === _this.visit.id;
      })) {
        this.component = 'SinceDuration';
        this.since = this.latestExams.find(function (e) {
          return e.visit_id === _this.visit.id;
        }).finished_at;
        return room ? 'Retour, ' + room.status : 'Retour<span class="gt-lg">, en att. de consultation</span>';
      }
      var newcomer = this.newcomers.find(function (v) {
        return v.id === _this.visit.id;
      });
      if (this.visit.is_appointment) {
        this.component = 'SinceDuration';
        this.since = newcomer.started_at;
        return room ? 'RDV, ' + room.status : 'RDV<span class="gt-lg">, en att. de consultation</span>';
      }
      if (newcomer) {
        this.component = 'SinceDuration';
        this.since = newcomer.started_at;
        return room ? 'Arrivant, ' + room.status : 'Arrivant<span class="gt-lg">, en att. de consultation</span>';
      }
      this.component = false;
      return 'Oups, aucune idée...';
    }
  },
  components: {
    SinceDuration: SinceDuration,
    StartStop: StartStop
  }
};