import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations } from 'vuex';
import SingleVisit from "./Single.vue";
import Toolbar from 'components/partials/Toolbar';
// import filters from 'src/services/filters'
import scroll from 'quasar/src/utils/scroll.js';;

// const { plural } = filters
var getScrollTarget = scroll.getScrollTarget,
  setVerticalScrollPosition = scroll.setVerticalScrollPosition;
export default {
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState({
    index: function index(state) {
      return state.entities.visits.index;
    },
    exams: function exams(state) {
      return state.auth.settings.practice.exams;
    },
    tcVisibility: function tcVisibility(state) {
      return state.auth.ui.visibilities.tc;
    },
    upcomingReturnsVisibility: function upcomingReturnsVisibility(state) {
      return state.auth.ui.visibilities.upcoming;
    }
  })), mapGetters('entities/visits', ['isUnordered', 'query', 'upcomingAppointments', 'upcomingReturns'])), mapGetters('entities/exams', ['upcomingList'])), {}, {
    upcomingListMinusVisibleFive: function upcomingListMinusVisibleFive() {
      var _this = this;
      return this.upcomingList.filter(function (exam) {
        return _this.upcomingReturns.some(function (v) {
          return v.id === exam.visit_id;
        });
      });
    },
    inLine: function inLine() {
      var _this2 = this;
      return this.index.filter(function (id) {
        return !_this2.upcomingAppointments.some(function (v) {
          return v.id === id;
        });
      }).filter(function (id) {
        return !_this2.upcomingReturns.some(function (v) {
          return v.id === id;
        });
      }).map(function (id) {
        return _this2.query().with('consultations.exams').where('id', id).first();
      });
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('auth', ['toggleTcVisibility', 'toggleUpcomingReturnsVisibility'])), {}, {
    tcVisibilityAction: function tcVisibilityAction() {
      var _this3 = this;
      this.toggleTcVisibility();
      return !this.tcVisibility || this.$nextTick(function () {
        var target = getScrollTarget(_this3.$refs.appointments.$el);
        var offset = _this3.$refs.appointments.$el.offsetTop - 350;
        setVerticalScrollPosition(target, offset, 150);
      });
    },
    upcomingReturnsVisibilityAction: function upcomingReturnsVisibilityAction() {
      var _this4 = this;
      this.toggleUpcomingReturnsVisibility();
      return !this.upcomingReturnsVisibility || this.$nextTick(function () {
        var target = getScrollTarget(_this4.$refs.upcomingReturns.$el);
        var offset = _this4.$refs.upcomingReturns.$el.offsetTop - 350;
        setVerticalScrollPosition(target, offset, 150);
      });
    }
  }),
  components: {
    Toolbar: Toolbar,
    SingleVisit: SingleVisit
  }
};