import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ConsultationDefault from "./SingleDefault.vue";
import ConsultationOngoing from "./SingleOngoing.vue";
import ConsultationDisabled from "./SingleDisabled.vue";
export default {
  name: 'SingleContainer',
  props: {
    visit: {
      type: Object,
      required: true
    },
    doctor: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      model: this.visit
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('entities/consultations', ['ongoing'])), {}, {
    component: function component() {
      var _this = this;
      var consultation = this.ongoing.find(function (c) {
        return c.user_id === _this.doctor.id && c.visit_id === _this.visit.id;
      });
      if (consultation) {
        this.setModel(consultation);
        return 'ConsultationOngoing';
      }
      this.setModel(this.visit);
      return 'ConsultationDefault';
    }
  }),
  methods: {
    setModel: function setModel(model) {
      this.model = model;
    }
  },
  components: {
    ConsultationDefault: ConsultationDefault,
    ConsultationOngoing: ConsultationOngoing,
    ConsultationDisabled: ConsultationDisabled
  }
};