import _toConsumableArray from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import _objectWithoutProperties from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js";
var _excluded = ["updated_at"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint camelcase: "off" */

import { mapState, mapGetters, mapMutations } from 'vuex';
import Form from 'src/services/form';
import AppInput from 'src/mixins/app/Input';
// import AppQuote from './AppQuote.vue'
// import ModeSelect from './ModeSelect.vue'
import PaymentMethodCounter from "./PaymentMethodCounter.vue";
import { evaluate } from 'mathjs';
import debounce from 'quasar/src/utils/debounce.js';;
import { isEqual } from 'lodash';
export default {
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    var _this$visit = this.visit,
      id = _this$visit.id,
      _this$visit$quote = _this$visit.quote,
      quote = _this$visit$quote === void 0 ? null : _this$visit$quote,
      _this$visit$invoice = _this$visit.invoice,
      invoice = _this$visit$invoice === void 0 ? null : _this$visit$invoice,
      _this$visit$third = _this$visit.third,
      third = _this$visit$third === void 0 ? null : _this$visit$third,
      _this$visit$payment = _this$visit.payment,
      payment = _this$visit$payment === void 0 ? null : _this$visit$payment,
      _this$visit$notes = _this$visit.notes,
      notes = _this$visit$notes === void 0 ? null : _this$visit$notes,
      _this$visit$updated_a = _this$visit.updated_at,
      updated_at = _this$visit$updated_a === void 0 ? null : _this$visit$updated_a,
      _this$visit$finished_ = _this$visit.finished_at,
      finished_at = _this$visit$finished_ === void 0 ? null : _this$visit$finished_;
    return {
      form: new Form({
        id: id,
        quote: quote,
        invoice: invoice,
        third: third,
        payment: payment,
        notes: notes,
        updated_at: updated_at,
        finished_at: finished_at
      }),
      showQuoteSuggestionsMenu: false,
      paymentMethodOptions: this.$store.state.auth.settings.payment_methods,
      hints: {},
      loading: false,
      rules: {
        max: function max(v) {
          return v.toString().length < 26 || 'Tant que ça?';
        },
        number: function number(v) {
          var _String;
          return ((_String = String(v)) === null || _String === void 0 ? void 0 : _String.match(/^[\d/+.()*-]{0,25}$/)) || 'Nombre ou calcul uniquement.';
        }
      },
      visitWithSameCount: {},
      confirmation: false,
      menu: false
    };
  },
  created: function created() {
    this.debounceVisitUpdate = debounce(this.updateVisit, 1000);
  },
  watch: {
    form: {
      handler: function handler(newValues) {
        // Touch updated_at at each update...
        var _this$form = _objectSpread({}, this.form),
          updated_at = _this$form.updated_at,
          form = _objectWithoutProperties(_this$form, _excluded);
        delete newValues.updated_at;

        // Avoid loops
        if (isEqual(newValues, form)) {
          return;
        }
        this.updated_at = new Date();
      },
      deep: true
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    // config: s => s.auth.settings.practice,
    paymentMethods: function paymentMethods(s) {
      return s.auth.settings.payment_methods;
    },
    allQuotes: function allQuotes(s) {
      return s.auth.settings.quotes;
    },
    allInvoices: function allInvoices(s) {
      return s.auth.settings.invoices;
    }
    // modifiers: s => s.auth.settings.modifiers
  })), mapGetters({
    exams: 'entities/exams/query',
    billed: 'entities/visits/billed',
    topQuotes: 'auth/topQuotes',
    topPaymentMethods: 'auth/topPaymentMethods',
    chunkedQuotes: 'auth/chunkedQuotes',
    visitsGroupedByRelation: 'entities/visits/groupedByRelation',
    originOptions: 'auth/originOptions',
    hospOptions: 'auth/hospOptions'
  })), {}, {
    hasCount: function hasCount() {
      return this.billed.filter(function (v) {
        return 'count' in v.payment;
      });
    },
    billedRelationWithCount: function billedRelationWithCount() {
      var _this = this;
      return this.visitsGroupedByRelation[this.visit.minuteKey].find(function (a) {
        return _this.hasCount.some(function (b) {
          return a.id === b.id;
        });
      });
    },
    /* mutableQuote: {
        get () {
            return this.form.quote.join(' ')
        },
        set (value) {
            this.inputQuote = String(value)
             if (value) {
                this.form.quote = String(value).match(/(\/?\w+(\+)?(\\+)?)+/g)
            } else {
                this.form.quote = []
            }
             this.debounceVisitUpdate()
        }
    }, */
    splitQuote: function splitQuote() {
      if (this.form.quote) {
        return String(this.form.quote).split('+');
      } else {
        return [];
      }
    },
    typedQuoteLetters: function typedQuoteLetters() {
      return !this.form.quote ? [] : _toConsumableArray(this.form.quote);
    },
    /* typingQuote () {
        return this.inputQuote.length === this.mutableQuote.length
            ? this.form.quote[this.form.quote.length - 1]
            : null
    }, */
    topFirstQuote: function topFirstQuote() {
      return this.topQuotes.slice(0, 4);

      /* if (this.form.quote.length === 1) {
          const firstQuote = this.topQuotes
              .find(q => q.name === this.form.quote[0])
           if (firstQuote) {
              const related = firstQuote.related.sort((a, b) => b.count - a.count)
              quoteSuggestions.unshift(...related)
          }
      }
       if (this.typingQuote) {
          let typing = this.typingQuote.slice()
          quoteSuggestions = quoteSuggestions
              .filter(q => String(q.name).includes(this.typingQuote))
           while (!quoteSuggestions.length && typing.length > 0) {
              typing = typing.slice(0, -1)
              quoteSuggestions = this.topQuotes
                  .filter(q => String(q.name).includes(typing)).slice(0, 3)
          }
      }
       return quoteSuggestions.slice(0, 4) */
    },

    quoteSuggestionsMenu: {
      get: function get() {
        return this.showQuoteSuggestionsMenu && this.quoteSuggestions.length > 0;
      },
      set: function set(value) {
        this.showQuoteSuggestionsMenu = value;
      }
    },
    quoteSuggestions: function quoteSuggestions() {
      var _this2 = this;
      return this.allInvoices.filter(function (i) {
        return i.quote !== _this2.form.quote;
      }).filter(function (i) {
        return !_this2.topFirstQuote.some(function (q) {
          return q.name === i.quote;
        });
      }).map(function (i) {
        i.score = _this2.typedQuoteLetters.reduce(function (acc, letter, index) {
          return acc + (i.quote.includes(letter) ? 1 : -10) + (
          // (this.typedQuoteLetters[index] === i.quote[index] ? 1 : 0) +
          _this2.form.quote.substring(0, i.quote.indexOf('+')) === i.quote.substring(0, i.quote.indexOf('+')) ? 10 : 0);
        }, 1);
        return i;
      }).filter(function (i) {
        return i.score > 0;
      }).sort(function (a, b) {
        return b.score - a.score;
      }).slice(0, 10);
    },
    invoiceSuggestions: function invoiceSuggestions() {
      var _this3 = this;
      var calculated = this.splitQuote.reduce(function (acc, curr) {
        var found = _this3.allQuotes.find(function (item) {
          return item.name === curr;
        });
        return found ? acc + Number(found.invoice) : acc;
      }, 0);
      var known = this.allInvoices.find(function (i) {
        return i.quote === _this3.form.quote;
      });
      var suggestions = [];
      if (calculated) {
        suggestions.push(calculated.toFixed(2));
      }
      if (!!known && Number(known.invoice) !== Number(calculated)) {
        suggestions.push(known.invoice);
      }
      return suggestions;
    },
    thirdSuggestions: function thirdSuggestions() {
      return this.invoiceSuggestions.length ? this.invoiceSuggestions.map(function (s) {
        return (s * 0.3).toFixed(2);
      }) : 0;
    },
    paymentMethodSuggestions: function paymentMethodSuggestions() {
      var _this4 = this;
      var tops = _toConsumableArray(this.topPaymentMethods);
      if (!this.form.invoice) {
        return tops.filter(function (m) {
          return m.name === 'gone';
        });
      }

      // const cc = { ...tops.find(m => m.name === 'cc') }
      var ccIndex = tops.findIndex(function (m) {
        return m.name === 'cc';
      });
      if (ccIndex !== -1) {
        var cc = _objectSpread({}, tops[ccIndex]);
        cc.short = 'CB #' + this.getAutoCount('cc');
        tops[ccIndex] = cc;
      }

      // const bq = { ...tops.find(m => m.name === 'bq') }
      var bqIndex = tops.findIndex(function (m) {
        return m.name === 'bq';
      });
      if (bqIndex !== -1) {
        var bq = _objectSpread({}, tops[bqIndex]);
        bq.short = 'BQ #' + this.getAutoCount('bq');
        tops[bqIndex] = bq;
      }
      if (this.billedRelationWithCount) {
        var sameIndex = tops.findIndex(function (m) {
          return m.name === _this4.billedRelationWithCount.payment.method;
        });
        if (sameIndex !== -1) {
          var same = _objectSpread({}, tops[sameIndex]);
          same.related = true;
          if (this.hasCount.some(function (v) {
            return v.id === _this4.billedRelationWithCount.id;
          })) {
            same.short = same.label + ' #' + this.billedRelationWithCount.payment.count;
          }
          tops.unshift(same);
        }
      }
      return tops.slice(0, 4);
    },
    hasHosp: function hasHosp() {
      var _this5 = this;
      return this.exams().where(function (exam) {
        return exam.visit_id === _this5.visit.id;
      }).where(function (exam) {
        return ['hosp', 'smur'].includes(exam.type);
      }).count();
    },
    isCounterNeeded: function isCounterNeeded() {
      return ['cc', 'bq'].includes(this.form.payment.method);
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('auth', ['toggleForm'])), {}, {
    clicQuote: function clicQuote(value) {
      this.form.quote = value;
      this.$refs.quoteInput.focus();
      return this.debounceVisitUpdate();
    },
    closeQuoteSuggestionsMenuAndUpdate: function closeQuoteSuggestionsMenuAndUpdate() {
      this.showQuoteSuggestionsMenu = false;
      return this.debounceVisitUpdate();
    },
    filterPaymentMethod: function filterPaymentMethod(value, update) {
      var _this6 = this;
      update(function () {
        if (value === '') {
          update(function () {
            _this6.paymentMethodOptions = _this6.paymentMethods;
          });
        } else {
          var needle = value.toLowerCase();
          _this6.paymentMethodOptions = _this6.paymentMethods.filter(function (v) {
            return v.label.toLowerCase().indexOf(needle) > -1;
          });
        }
      }, function (ref) {
        if (value !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
          ref.moveOptionSelection(1, true); // focus the first selectable option
          ref.toggleOption(ref.options[ref.optionIndex], true); // toggle the focused option
        }
      });
    },
    updatePaymentMethod: function updatePaymentMethod(value) {
      var related = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.form.payment = {
        method: value
      };
      if (related) {
        this.updateCount(this.billedRelationWithCount.payment.count);
      } else if (this.isCounterNeeded) {
        this.form.payment.count = this.getAutoCount();
      }
      return this.debounceVisitUpdate();
    },
    getAutoCount: function getAutoCount() {
      var _this7 = this;
      var pm = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      pm = pm || this.form.payment.method;
      var visits = this.hasCount.filter(function (v) {
        return v.id !== _this7.visit.id;
      }).filter(function (v) {
        return v.payment.method === pm;
      });
      var count = 1;
      while (visits.some(function (v) {
        return v.payment.count === count;
      })) {
        count++;
      }
      return count;
    },
    updateCount: function updateCount(value) {
      var _this8 = this;
      this.form.payment.count = Number(value);
      var exists = this.hasCount.filter(function (v) {
        return v.id !== _this8.visit.id;
      }).filter(function (v) {
        return v.payment.method === _this8.form.payment.method;
      }).find(function (v) {
        return v.payment.count === value;
      });

      // Non-unique Number need confirmation.
      if (exists) {
        this.visitWithSameCount = exists;
        this.confirmation = true;
        return false;
      }
      this.debounceVisitUpdate();
    },
    moveNext: function moveNext(event) {
      var inputs = this.$el.querySelectorAll('input, button');
      for (var item in inputs) {
        if (inputs[item].tabIndex > event.target.tabIndex) {
          return inputs[item].focus();
        }
      }
    },
    moveBack: function moveBack(event) {
      var query = this.$el.querySelectorAll('input, button');
      var inputs = [].slice.call(query, 0).reverse();
      for (var item in inputs) {
        if (inputs[item].tabIndex < event.target.tabIndex) {
          return inputs[item].focus();
        }
      }
    },
    /* filterQuotesFn (val, update) {
        update(() => {
            if (val === '') {
                this.quotesOptions = this.quotesOptions
            } else {
                const needle = val.toLowerCase()
                this.quotesOptions = this.quotesOptions.filter(
                    v => v.toLowerCase().indexOf(needle) > -1
                )
            }
        })
    }, */
    /* createQuoteValue (val, done) {
        val = val.toLowerCase()
         if (val.indexOf(',') > -1) {
            val = val.replace(/\s+/g, '')
            this.form.payment.quote = [ ...this.form.payment.quote, ...val.split(',') ]
        } else if (val.indexOf(' ') > -1) {
            this.form.payment.quote = [ ...this.form.payment.quote, ...val.split(',') ]
        } else {
            this.form.payment.quote.push(val)
        }
         done(val, 'add')
    }, */
    doMaths: function doMaths(field) {
      var expression = String(this.form[field]).replace(/,/g, '.');
      if (expression.replace(/\s/g, '').match(/^[\d/+.()*-]+$/)) {
        this.form[field] = parseFloat(evaluate(expression).toFixed(2));
      }
      this.debounceVisitUpdate();
    },
    reopenVisit: function reopenVisit() {
      var _this9 = this;
      this.loading = true;
      this.form.finished_at = null;
      // Instant Update
      return this.form.submit('entities/visits/sync').then(function () {
        return _this9.$store.dispatch('entities/visits/sort');
      }).finally(function () {
        _this9.loading = false;
      });
    },
    useInvoiceSuggestion: function useInvoiceSuggestion(suggestion) {
      this.form.invoice = suggestion;
      // this.$refs.third.focus()
      this.debounceVisitUpdate();
    },
    useThirdSuggestion: function useThirdSuggestion(suggestion) {
      this.form.third = suggestion;
      // $this.$refs.third.focus()
      this.debounceVisitUpdate();
    },
    /* autoUpdatePaymentMethod (event) {
        const method = this.topPaymentMethods[0]
         if (this.form.payment.method) { return }
         this.form.payment.method = method.name
        this.moveNext(event)
        this.updatePaymentMethod(method.name, !!method.related || false)
    }, */
    updateVisit: function updateVisit() {
      var _this10 = this;
      this.loading = true;
      // Avoid malformated time validation error.
      delete this.form.finished_at;
      return this.form.submit('entities/visits/sync').finally(function () {
        _this10.loading = false;
      });
    },
    finishVisit: function finishVisit() {
      var _this11 = this;
      this.loading = true;
      return this.form.submit('entities/visits/end').then(function () {
        _this11.toggleForm(0);
      }).finally(function () {
        _this11.loading = false;
      });
    }
  }),
  components: {
    AppInput: AppInput,
    // AppQuote,
    // ModeSelect,
    PaymentMethodCounter: PaymentMethodCounter
  }
};