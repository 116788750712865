import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import SingleBase from "./SingleBase";
import { mapState, mapGetters, mapActions } from 'vuex';
import date from 'quasar/src/utils/date.js';;
var getDateDiff = date.getDateDiff;
export default {
  mixins: [SingleBase],
  data: function data() {
    return {
      flat: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    now: function now(s) {
      return s.date.now;
    },
    rooms: function rooms(s) {
      return s.auth.settings.practice.rooms;
    }
  })), mapGetters({
    find: 'entities/visits/find'
  })), {}, {
    color: function color() {
      var visit = this.find(this.model.visit_id);
      var room = this.rooms.find(function (room) {
        return room.name === visit.notes.room;
      });
      return room ? room.color : 'primary';
    },
    confirmationNeeded: function confirmationNeeded() {
      var min = 1;
      var duration = getDateDiff(this.now, this.model.started_at, 'minutes');
      return duration < min;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions({
    stop: 'entities/consultations/stop'
  })), {}, {
    action: function action() {
      this.confirmationNeeded ? this.confirmEnd = true : this.doStop();
    },
    doStop: function doStop() {
      var _this = this;
      this.loading = true;
      this.stop(this.model.id).finally(function () {
        _this.loading = false;
      });
    },
    close: function close() {
      this.loading = false;
      this.confirmEnd = false;
    }
  })
};