import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import Shift from 'src/store/models/Shift';
import User from 'src/store/models/User';
import date from 'quasar/src/utils/date.js';;
var subtractFromDate = date.subtractFromDate,
  addToDate = date.addToDate,
  getMinDate = date.getMinDate,
  buildDate = date.buildDate,
  getDateDiff = date.getDateDiff;
export function todaysMedicalShifts() {
  return Shift.query().with('user').where(function (shift) {
    return shift.isMedical() && getDateDiff(shift.start_at, new Date(), 'days') === 0;
  }).get().sort(function (a, b) {
    return a.type.id - b.type.id;
  });
}
export function pastShifts() {
  return Shift.query().with('user').where(function (shift) {
    return getDateDiff(shift.start_at, new Date(), 'days') < 0;
  }).get().sort(function (a, b) {
    return a.type.id - b.type.id;
  });
}

// export function doctors (state, getters) {
//     return getters.todaysMedicalShifts
//         .map(shift => ({ ...shift.user, shift: shift }))
//         .filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) // Unique.
// }

export function ongoingShifts(state, getters, rootState, rootGetters) {
  return getters.todaysMedicalShifts.filter(function (shift) {
    if (getters.todaysMedicalShifts.length <= 1) {
      return true;
    }
    if (rootGetters['entities/consultations/ongoing'].some(function (c) {
      return c.user_id === shift.user_id;
    })) {
      return true;
    }
    var shiftVisibilityIndex = rootState.auth.ui.visibilities.shifts.findIndex(function (s) {
      return s.id === shift.id;
    });
    if (shiftVisibilityIndex !== -1) {
      return rootState.auth.ui.visibilities.shifts[shiftVisibilityIndex].visibility;
    }

    // Affichage des médecins 90 minutes avant et 90 min après la garde ou à minuit au max.
    var endOfDay = buildDate({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    });
    var now = rootState.date.now.getTime();
    var from = subtractFromDate(shift.start_at, {
      minutes: 30
    }).getTime();
    var to = getMinDate(addToDate(shift.end_at, {
      minutes: 30
    }), endOfDay);
    if (now > to || now < from) {
      return false;
    }
    if (shift.type.id >= 20) {
      return true;
    }
    return !getters.todaysMedicalShifts.some(function (child) {
      if (child.parent_id !== shift.id) {
        return false;
      }
      if (child.start_at.getTime() === shift.start_at.getTime() && child.end_at.getTime() === shift.end_at.getTime()) {
        return true;
      }
      if (child.start_at.getTime() === shift.start_at.getTime() && subtractFromDate(child.end_at, {
        minutes: 30
      }).getTime() >= now) {
        return true;
      }
      if (child.end_at.getTime() === shift.end_at.getTime() && addToDate(child.start_at, {
        minutes: 30
      }).getTime() <= now) {
        return true;
      }
      return false;
    });
  });
}
export function ongoingShiftsUsers(state, getters) {
  return getters.ongoingShifts.map(function (shift) {
    return User.find(shift.user_id);
  }).filter(function (v, i, s) {
    return s.indexOf(v) === i;
  }); // Unique.
}

/* export function doctorsWithConsultations (state, getters) {
    return getters.doctors
        .filter(user => Consultation.all().some(consultation => {
            return consultation.user_id === user.id
        }))
} */

export function assistant() {
  var shift = Shift.query().with('user').where(function (shift) {
    return shift.isAssistant();
  }).first();
  return shift ? shift.user : {
    avatarUrl: 'https://ucarecdn.com/afbed8c0-db48-4571-af0f-da29ad375023/',
    fullname: 'Personne'
  };
}