import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'src/services/form';
import filters from 'src/services/filters';
// import AppTooltip from 'src/mixins/app/Tooltip'

var time = filters.time;
export default {
  props: {
    visit: Object,
    focus: {
      type: String,
      default: 'started_at'
    }
  },
  data: function data() {
    return {
      loading: false,
      confirm: false,
      form: new Form({
        id: this.visit.id,
        notes: this.visit.notes,
        started_at: time(this.visit.started_at),
        finished_at: time(this.visit.finished_at)
      })
    };
  },
  computed: {
    start: function start() {
      return time(this.visit.started_at);
    },
    created_at: function created_at() {
      return "Enregistrement \xE0 ".concat(time(this.visit.created_at));
    },
    isTrashable: function isTrashable() {
      var _this = this;
      return !this.$store.getters['entities/visits/withConsultations'].some(function (v) {
        return v.id === _this.visit.id;
      });
    }
  },
  methods: {
    refresh: function refresh() {
      this.form.started_at = time(this.visit.started_at);
    },
    toggleIsAppointment: function toggleIsAppointment() {
      this.form.notes.is_appointement = !this.form.notes.is_appointement;
    },
    submit: function submit() {
      this.form.submit('entities/visits/sync').then(this.$refs.menu.hide());
    },
    remove: function remove() {
      var _this2 = this;
      this.loading = true;
      return this.$store.dispatch('entities/visits/remove', this.visit.id).then(function () {
        _this2.$refs.menu.hide();
        _this2.$store.dispatch('entities/visits/sort');
      }).finally(function () {
        _this2.loading = false;
        _this2.confirm = false;
      });
    }
  }

  /* components: {
      AppTooltip
  } */
};