import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.regexp.search.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import filters from 'src/services/filters';
export default {
  name: 'Toolbar',
  data: function data() {
    return {
      searching: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('entities/visits', ['index', 'search'])), mapGetters('entities/visits', ['all', 'inWaitingLine', 'newcomers', 'returners', 'finished', 'isUnordered', 'upcomingAppointments'])), {}, {
    visitsCount: function visitsCount() {
      return this.all().length;
    },
    pattern: {
      get: function get() {
        return this.search;
      },
      set: function set(value) {
        this.setSearch(value);
        this.sort();
      }
    }
  }),
  methods: _objectSpread({}, mapActions('entities/visits', ['sort', 'setSearch'])),
  filters: filters
};