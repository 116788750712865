import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.slice.js";
import Visit from 'src/store/models/Visit';
import Consultation from 'src/store/models/Consultation';
import Exam from 'src/store/models/Exam';
import filters from 'src/services/filters';
import date from 'quasar/src/utils/date.js';;
var subtractFromDate = date.subtractFromDate;
var method = filters.method;
export function ordering(state, getters, rootState, rootGetters) {
  var latestExams = Exam.getters('latestAnticipatedEnd');
  return Visit.query().where(function (v) {
    return v.isOngoing();
  })
  // .where(v => getDateDiff(v.started_at, new Date(), 'days') === 0).get()
  .get().filter(function (v) {
    return "".concat(v.patient.firstname, " ").concat(v.patient.lastname).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(state.search || ''); // Force String if state.search is null
  }).sort(function (a, b) {
    // Ongoing Visits First
    // if (a.isFinished() && b.isOngoing()) { return 1 }
    // if (a.isOngoing() && b.isFinished()) { return -1 }

    // SMUR = Urgence absolue
    var smur = latestExams.filter(function (e) {
      return e.type === 'smur' && e.isOngoing();
    });
    if (smur.some(function (e) {
      return e.visit_id === a.id;
    })) return -1;
    if (smur.some(function (e) {
      return e.visit_id === b.id;
    })) return 1;
    if (a.isFinished() && b.isFinished) {
      return new Date(a.finished_at) - new Date(b.finished_at);
    }

    // Ongoing Consultations First
    if (getters.withOngoingConsultation.some(function (v) {
      return v.id === b.id;
    })) {
      return 1;
    }
    if (getters.withOngoingConsultation.some(function (v) {
      return v.id === a.id;
    })) {
      return -1;
    }

    // In Rooms Patients
    if (a.priority < b.priority) {
      return 1;
    }
    if (a.priority > b.priority) {
      return -1;
    }

    // Missing infos
    if (rootGetters['entities/consultations/withoutExam'].some(function (c) {
      return c.visit_id === b.id;
    })) {
      return 1;
    }
    if (rootGetters['entities/consultations/withoutExam'].some(function (c) {
      return c.visit_id === a.id;
    })) {
      return -1;
    }

    // Returners are nexts
    if (getters.returners.some(function (r) {
      return r.id === a.id;
    }) && !getters.returners.some(function (r) {
      return r.id === b.id;
    })) {
      return -1;
    }
    if (!getters.returners.some(function (r) {
      return r.id === a.id;
    }) && getters.returners.some(function (r) {
      return r.id === b.id;
    })) {
      return 1;
    }

    // Then Newcomers
    if (getters.newcomers.some(function (n) {
      return n.id === a.id;
    }) && !getters.newcomers.some(function (n) {
      return n.id === b.id;
    })) {
      return -1;
    }
    if (!getters.newcomers.some(function (n) {
      return n.id === a.id;
    }) && getters.newcomers.some(function (n) {
      return n.id === b.id;
    })) {
      return 1;
    }
    var examA = latestExams.find(function (exam) {
      return exam.visit_id === a.id;
    });
    var examB = latestExams.find(function (exam) {
      return exam.visit_id === b.id;
    });
    var dateA = examA ? new Date(examA.anticipatedEnd) : new Date(a.started_at);
    var dateB = examB ? new Date(examB.anticipatedEnd) : new Date(b.started_at);
    return dateA - dateB;
  });
}
export function ordered(state, getters) {
  return getters.ordering.map(function (visit) {
    return visit.id;
  });
}
export function isUnordered(state, getters) {
  return JSON.stringify(state.index) !== JSON.stringify(getters.ordered);
}
export function withConsultations() {
  return Visit.query().has('consultations').get();
}
export function withOngoingConsultation() {
  return Consultation.getters('ongoing').map(function (consultation) {
    return Visit.find(consultation.visit_id);
  });
}
export function withConsultationWoExam() {
  return Consultation.getters('withoutExam').map(function (consultation) {
    return Visit.find(consultation.visit_id);
  });
}
export function newcomers() {
  return Visit.query().where(function (v) {
    return v.isOngoing();
  }).hasNot('consultations').get();
}
export function returners(state, getters) {
  return Visit.query().where(function (v) {
    return v.isOngoing();
  }).whereHas('consultations', function (query) {
    // Where Has Finished Consultations
    query.where(function (consultation) {
      return consultation.isFinished();
    });
  }).whereHasNot('exams', function (query) {
    // Where Has Not Ongoing Exam
    query.where(function (exam) {
      return exam.isOngoing();
    });
  }).get().filter(function (record) {
    return !getters.withConsultationWoExam.some(function (visit) {
      return visit.id === record.id;
    }) && !getters.withOngoingConsultation.some(function (visit) {
      return visit.id === record.id;
    });
  });
}
export function inWaitingLine(state, getters) {
  return getters.newcomers.concat(getters.returners);
}
export function upcomingAppointments(state, getters, rootState) {
  return getters.ordering.filter(function (v) {
    return v.isOngoing() && subtractFromDate(v.started_at, {
      minutes: 30
    }) >= rootState.date.now;
  });
}
export function upcomingReturns(state) {
  var upcomingExams = Exam.getters('upcomingList');
  return state.index.slice(5).filter(function (id) {
    return upcomingExams.some(function (exam) {
      return exam.visit_id === id;
    });
  }).map(function (id) {
    return Visit.query().with('consultations.exams').where(function (v) {
      return v.id === id;
    }).first();
  });
}
export function inRooms() {
  return Visit.query().where(function (v) {
    return 'room' in v.notes;
  }).get();
}
export function billed() {
  return Visit.query().with('consultations.exams').where(function (v) {
    return v.isBilled();
  }).get();
}
export function finished() {
  return Visit.query().with('consultations.exams').where(function (visit) {
    return visit.isFinished();
  }).orderBy('finished_at', 'asc').get();
}
export function groupedByRelation() {
  return Visit.query()
  // .where(v => v.isOngoing())
  .get().reduce(function (acc, visit) {
    var minuteKey = visit.minuteKey;
    if (!acc[minuteKey]) {
      acc[minuteKey] = [];
    }
    acc[minuteKey].push(visit);
    return acc;
  }, {});
}
export function hasInconsistencies(state, getters, rootState, rootGetters) {
  return getters.billed.reduce(function (acc, visit) {
    if (!visit.invoice && !['free', 'gone'].includes(visit.payment.method)) {
      acc.push({
        id: visit.id,
        description: 'Le montant de la facture est manquant.'
      });
    }
    if (visit.payment.method === 't-unpaid' && !visit.third) {
      acc.push({
        id: visit.id,
        description: method(visit.payment.method) + ' implique la présence du tiers payant pour le calcul du CA.'
      });
    }
    if (visit.payment.method === 'unpaid' && visit.third) {
      acc.push({
        id: visit.id,
        description: 'Le mode ' + method(visit.payment.method) + ' est incohérent avec la présence d\'un tiers payant. Choisir tiers impayé ou supprimer le tiers payant.'
      });
    }
    if (visit.payment.method === 'free' && (visit.invoice || visit.third)) {
      acc.push({
        id: visit.id,
        description: 'Le mode ' + method(visit.payment.method) + ' est incohérent avec la présence d\'une facture ou d\'un tiers payant.'
      });
    }
    if (rootGetters['auth/wirePaymentMethods'].includes(visit.payment.method) && visit.third) {
      acc.push({
        id: visit.id,
        description: 'Le mode de paiement ' + method(visit.payment.method) + ' est incohérent avec la présence d\'un tiers payant.'
      });
    }
    if (visit.invoice - visit.third < 0) {
      acc.push({
        id: visit.id,
        description: 'Le tiers est supérieur à la facture.'
      });
    }
    return acc;
  }, []);
}