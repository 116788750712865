import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ExamDefault from "./SingleDefault.vue";
import ExamOngoing from "./SingleOngoing.vue";
import ExamFinished from "./SingleFinished.vue";
export default {
  name: 'SingleContainer',
  props: {
    visit_id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('entities/exams', ['ongoing', 'finished'])), {}, {
    component: function component() {
      if (this.hasExamType('ongoing')) {
        return 'ExamOngoing';
      }
      if (this.hasExamType('finished')) {
        return 'ExamFinished';
      }
      return 'ExamDefault';
    }
  }),
  methods: {
    hasExamType: function hasExamType(status) {
      var _this = this;
      // Has Ongoing or Finished Exam By Type.
      return this[status].some(function (exam) {
        return _this.visit_id === exam.visit_id && _this.type === exam.type;
      });
    }
  },
  components: {
    ExamDefault: ExamDefault,
    ExamOngoing: ExamOngoing,
    ExamFinished: ExamFinished
  }
};