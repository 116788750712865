import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es7.promise.finally.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Sidebar from 'src/components/sidebar/Sidebar';
import { mapState } from 'vuex';
import Loading from 'quasar/src/plugins/Loading.js';import date from 'quasar/src/utils/date.js';;
var formatDate = date.formatDate;
export default {
  preFetch: function preFetch(_ref) {
    var store = _ref.store;
    Loading.show();

    // Order matters!
    return store.dispatch('date/watch').then(function () {
      return store.dispatch('auth/settings');
    }).then(function () {
      return store.dispatch('entities/visits/fetch');
    }).then(function () {
      return store.dispatch('entities/shifts/fetch');
    }).finally(function () {
      return Loading.hide();
    });
  },
  data: function data() {
    return {
      formatDate: formatDate
    };
  },
  computed: _objectSpread({}, mapState({
    now: function now(s) {
      return s.date.now;
    }
  })),
  methods: {
    resetScroll: function resetScroll(el, done) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      done();
    }
  },
  components: {
    Sidebar: Sidebar
  }
};