import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import AuthOptions from "./AuthOptions";
import CClock from "./Clock";
import DatePicker from "./DatePicker.vue";
import dom from 'quasar/src/utils/dom.js';import scroll from 'quasar/src/utils/scroll.js';;
var offset = dom.offset;
var getScrollTarget = scroll.getScrollTarget,
  setVerticalScrollPosition = scroll.setVerticalScrollPosition;
export default {
  props: {
    realtime: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      viewport: 'top'
    };
  },
  mounted: function mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: _objectSpread(_objectSpread({}, mapState('auth', ['user'])), {}, {
    avatar: function avatar() {
      return process.env.API + this.user.avatar;
    }
  }),
  methods: {
    handleScroll: function handleScroll() {
      var el = document.getElementById('finances');
      if (el && offset(el).top > 600) {
        this.viewport = 'top';
      } else {
        this.viewport = 'finances';
      }
    },
    scrollTo: function scrollTo(element) {
      var el = document.getElementById(element);
      var target = getScrollTarget(el);
      if (!el) {
        return;
      }
      this.$nextTick(function () {
        return setVerticalScrollPosition(target, offset(el).top);
      });
    }
  },
  components: {
    CClock: CClock,
    DatePicker: DatePicker,
    AuthOptions: AuthOptions
  }
};