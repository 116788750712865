import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ConsultationsList from 'components/consultations/List';
import ExamsList from 'components/exams/List';
import RoomsList from 'components/visits/Rooms';
export default {
  name: 'Procedures',
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('entities/visits', ['inWaitingLine',
  // 'withConsultations',
  'withOngoingConsultation'])), {}, {
    canToggleRoom: function canToggleRoom() {
      var _this = this;
      return this.inWaitingLine.some(function (v) {
        return v.id === _this.visit.id;
      }) || this.withOngoingConsultation.some(function (v) {
        return v.id === _this.visit.id;
      });
    },
    hasRoom: function hasRoom() {
      return !!this.visit.notes.room;
    }
  }),
  components: {
    ConsultationsList: ConsultationsList,
    RoomsList: RoomsList,
    ExamsList: ExamsList
  }
};