import _toConsumableArray from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import Stat from 'src/store/models/Stat';
export function practices() {
  var order = ['lenval', 'lamartine', 'smolett', 'ariane', 'mandelieu', 'cagnes-sur-mer'];
  return _toConsumableArray(new Set(Stat.all().map(function (item) {
    return item.practice;
  }))).sort(function (a, b) {
    return order.indexOf(a) - order.indexOf(b);
  });
}
export function monthData(state) {
  return Stat.all().filter(function (item) {
    return item.month === state.month;
  });
}
export function yearsWithData() {
  return Stat.all().reduce(function (acc, item) {
    return acc.concat(item.year);
  }, []).filter(function (v, i, s) {
    return s.indexOf(v) === i;
  }).sort(); // Unique.
}

export function monthsWithData(state) {
  return Stat.query().where(function (item) {
    return item.year === state.year;
  }).get().reduce(function (acc, item) {
    return acc.concat(item.month);
  }, []).filter(function (v, i, s) {
    return s.indexOf(v) === i;
  }); // Unique.
  // .filter(month => month !== new Date().getMonth() + 1) // Remove Current Month.
}

// Filter data based on months with data
export function relevantData(state, getters) {
  return Stat.query().where(function (item) {
    return getters.monthsWithData.includes(item.month);
  }).get();
}
export function maxVisits(state, getters) {
  return Stat.query().max('visits');
}

// Array of average visits count by day of week
export function dayAverage(state, getters) {
  return getters.practices.map(function (practice) {
    return {
      practice: practice,
      data: _toConsumableArray(Array(7).keys()).map(function (index) {
        var days = getters.monthData.filter(function (item) {
          return item.dayofweek === index + 1 && item.practice === practice;
        });
        var sum = days.reduce(function (acc, item) {
          return acc + item.visits;
        }, 0);
        return Math.round(sum / days.length);
      })
    };
  }).concat({
    practice: 'all',
    data: _toConsumableArray(Array(7).keys()).map(function (index) {
      var days = getters.monthData.filter(function (item) {
        return item.dayofweek === index + 1;
      });
      var sum = days.reduce(function (acc, item) {
        return acc + item.visits;
      }, 0);
      return Math.round(sum / days.length);
    })
  });
}

// Array of average visits count for working days and week ends
export function workingDaysAgainstWeekEnds(state, getters) {
  return _toConsumableArray(Array(2).keys()).map(function (index) {
    var days = getters.monthData.filter(function (item) {
      return index ? item.dayofweek >= 6 : item.dayofweek < 6;
    });
    var sum = days.reduce(function (acc, item) {
      return acc + item.visits;
    }, 0);
    return Math.round(sum / days.length);
  });
}

// Turnovers By Practices
export function turnovers(state, getters) {
  return _toConsumableArray(Array(12).keys()).map(function (index) {
    return getters.practices.map(function (practice) {
      return {
        practice: practice,
        turnover: getters.relevantData.filter(function (item) {
          return item.month === index + 1 && item.practice === practice;
        }).reduce(function (acc, item) {
          return acc + item.turnover;
        }, 0)
      };
    }).concat({
      practice: 'all',
      turnover: getters.relevantData.filter(function (item) {
        return item.month === index + 1;
      }).reduce(function (acc, item) {
        return acc + item.turnover;
      }, 0)
    });
  });
}

// Visists Count By Month
export function visitsCounts(state, getters) {
  return _toConsumableArray(Array(12).keys()).map(function (index) {
    return getters.practices.map(function (practice) {
      return {
        practice: practice,
        visits: getters.relevantData.filter(function (item) {
          return item.month === index + 1 && item.practice === practice;
        }).reduce(function (acc, item) {
          return acc + item.visits;
        }, 0)
      };
    }).concat({
      practice: 'all',
      visits: getters.relevantData.filter(function (item) {
        return item.month === index + 1;
      }).reduce(function (acc, item) {
        return acc + item.visits;
      }, 0)
    });
  });
}

// Average Year Turnover
export function averageTurnovers(state, getters) {
  return getters.practices.map(function (practice) {
    return {
      practice: practice,
      turnover: getters.relevantData.filter(function (item) {
        return item.practice === practice;
      }).reduce(function (acc, item) {
        return acc + item.turnover;
      }, 0) / getters.monthsWithData.length
    };
  }).concat({
    practice: 'all',
    turnover: getters.relevantData.reduce(function (acc, item) {
      return acc + item.turnover;
    }, 0) / getters.monthsWithData.length
  });
}
export function averageVisitsCount(state, getters) {
  return getters.practices.map(function (practice) {
    return {
      practice: practice,
      visits: getters.relevantData.filter(function (item) {
        return item.practice === practice;
      }).reduce(function (acc, item) {
        return acc + item.visits;
      }, 0) / getters.monthsWithData.length
    };
  }).concat({
    practice: 'all',
    visits: getters.relevantData.reduce(function (acc, item) {
      return acc + item.visits;
    }, 0) / getters.monthsWithData.length
  });
}